import knownProvidersService from '../../../../services/notisphere/known-providers'

export default {
    data() {
        return {
            item: {
                account_name: null,
                dhcidn: null,
            },
            formType: 'INSERT',
            labelName: {
                account_name: 'Account Name',
                dhcidn: 'IDN/Provider Name',
            },
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Provider',
                    isDisabled: false
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Provider',
                    isDisabled: true
                }
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(item) {
            this.formType = 'UPDATE'
            this.getLocation(item)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                this.createKnownProvider()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.updateKnownProvider()
            }
        },
        updateKnownProvider() {
            knownProvidersService.update_KnownProvider(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createKnownProvider() {
            knownProvidersService.create_KnownProvider(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        getLocation(item) {
            knownProvidersService.getknownProvider(item).then(resp =>{
                if (!resp.error){
                    this.item = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        }
    }
}