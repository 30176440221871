import { TYPICAL_STATUS} from '../../../misc/commons'
import knownProvidersService from '../../../services/notisphere/known-providers'
import LocationModal from './LocationModal'
import moment from 'moment-timezone'
export default {
    components: {
        LocationModal
    },
    data(){
        return{
            total: 0,
            items: [],
            searchQuery: {},
            search: {
                dhcidn: null,
                accountName: null,
                city: null,
                status: null,
            },
            status: null,
            locationPreProcessor_status: TYPICAL_STATUS,
            providerOrgId: null,
            header: [
                {
                    label: 'Account Name',
                    name: 'account_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'IDN/Provider Name',
                    name: 'dhcidn',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Last Update',
                    name: 'modifiedDatetime',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                }
            ],
            locationModalKey: 0,
            listIDN: [],
            listAccounts: [],
        }
    },
    mounted() {
        this.getSearchMeta()
    },
    methods: {
        getSearchMeta() {
            knownProvidersService.knownPro_getSearchMeta().then(resp => {
                if (!resp.error) {
                    var data = resp.data.d
                    this.listIDN = [
                        ...data.idn.map(x => { return { text: x, value: x } })
                    ]
                    this.listAccounts = [
                        ...data.accounts.map(x => { return { text: x, value: x } })
                    ]
                }
            })
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderInsert()
            })
        },
        updateLocation(payload) {
            // this.locationModalKey += 1
            // this.$nextTick(() => {
            //     this.$refs.locationModal.forceRerenderUpdate(payload.location_id)
            // })
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            knownProvidersService.findLocation_knownProviders({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }).then(resp=>{
                if(!resp.error) {
                    resp.data.d.c.forEach((x, i) => {
                        x.modifiedDatetime=x.modifiedDatetime!=null? moment(String(x.modifiedDatetime)).format('YYYY-MM-DD hh:mm'):null
                    })
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        editJob(item, e) {
            this.proFieModalKey += 1
            this.$nextTick(()=>{
                this.$refs.locationModal.forceRerenderUpdate(item)
            })
        },
        deleteJob(item, e) {
            knownProvidersService.deleteKnownProvider(item.id, e.target.innerText).then(resp => {
                if (!resp.error) {
                    this.fetchData()
                    if(resp.data.d == 'INACTIVE'){
                        this._showToast('Provider deactivated successfully!')
                    }
                    else{
                        this._showToast('Provider activated successfully!')
                    }
                }
            })

            if (e) {
                e.stopPropagation()
            }
        },
    }
}